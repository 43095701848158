<nav class="navbar navbar-expand-md">
  <button (click)="toggleClass()" [class.active]="classApplied" aria-label="Menu" class="menu"> 
  <div class="navbar-toggle" id="navbarColl">
     <div class="line"></div>
  </div>
  </button>
  <div class="navbar-brand"><img src="assets/images/logo.png" alt=""></div>


  

  <nav class="navbar-nav" [class.active]="classApplied" >
     <li><a class="nav-item" [routerLink]="['/videos']" (click)="toggleClass()">Videos</a></li>
     <li><a class="nav-iten" [routerLink]="['/contact']"(click)="toggleClass()" >Contact</a></li>
     <li><a class="nav-item" [routerLink]="['/about']" (click)="toggleClass()">About</a></li>
  </nav>
</nav>


