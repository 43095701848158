  <div class="container-fluid">
    <div *ngIf="videos"  class="video">
      <iframe width="560" height="315" [src]='safeURL' title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen=""></iframe>
      <h1>Overview </h1>
      <div class="row"> 
        <div class="col-lg-9">
          <p>{{ videos.description }}</p>
         <p><a href="{{videos.source}}">Watch on YouTube</a> for 4k experience</p> 

          <p> <strong>Freelance</strong></p>
        </div>
        <!-- <iframe [src]='safeURL' frameborder="0" allowfullscreen></iframe> -->
        <div class="col-lg-2 offset-lg-1">
          <h2 class="break mb-2"><strong>Role</strong></h2>
          <p class="break">Director, Videographer, Editor</p>
        </div>
      </div>
    </div>
  </div>