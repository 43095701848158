import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Videos, videos } from '../videos';

import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.scss']
})

export class VideosComponent implements OnInit {
  videos: Videos|undefined;
  safeURL;

  constructor(
    private route: ActivatedRoute,
    private _sanitizer: DomSanitizer
  ) { 
    // console.log(videos.find(videos => videos.description))
    const routeParams = this.route.snapshot.paramMap;
    const videosIdFromRoute = Number(routeParams.get('videosId'));

    this.videos = videos.find(videos => videos.id === videosIdFromRoute);
    const videoLink = this.videos?.url;
    const fd = videoLink?.toString();
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(fd!);
  }

  ngOnInit(): void {

      // First get the product id from the current route.
  const routeParams = this.route.snapshot.paramMap;
  const videosIdFromRoute = Number(routeParams.get('videosId'));

  // Find the product that correspond with the id provided in route.
  this.videos = videos.find(videos => videos.id === videosIdFromRoute);
  }

}
