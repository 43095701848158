<footer>
    <ul>
        <li>
            <a [routerLink]="['/videos']" >Videos</a>
        </li>
        <li>
            <a [routerLink]="['/contact']" >Contact</a>
        </li>
        <li>
            <a [routerLink]="['/about']" >About</a>
        </li>        
    </ul>
    <!-- <div class="footer-icons">
      <a href="#"><i class="fab fa-instagram"></i></a>
      <a href="#"><i class="fab fa-tiktok"></i></a>
      <a href="#"><i class="fab fa-youtube"></i></a>     
    </div> -->
  </footer>