<div class="banner">
    <div class="about">
    <h2>
        Director / Videographer</h2>
    <p>Hi, my name is Ricky Ramirez and I am from Chicago. I create cinematic videos, for music videos and commercials. Check out some of my video work.</p><a class="btn-pink" [routerLink]="['/videos']" >Videos</a>
    </div>
    <div class="digital">
    <div class="gm">
        <div class="gameboy">
        <div class="gameboy-screen">
            <div class="display">
            <div class="glare"></div>
            <h2 id="title" [class.d-none]="classApplied">PORTFOLIO</h2>
            <button class="btn-pink" id="btn-start"  (click)="toggleClass()" [class.d-none]="classApplied">Select Start</button>
            <div id="iconlinks" [class.d-block]="classApplied">
                <div class="row"> 
                <div class="col-md-12"><a [routerLink]="['/videos']" >
                    <p>Videos</p></a><img class="bg" src="assets/images/skyline.png" alt=""></div>
                </div>
            </div>
            </div>
        </div>
        <div class="gameboy-controls">
            <div class="inputs">
            <div class="dpad">
                <div class="left"></div>
                <div class="up"></div>
                <div class="right"></div>
                <div class="down"></div>
            </div>
            <div class="buttons">
                <div class="button-b"></div>
                <div class="button-a"></div>
            </div>
            <div class="selection">
                <div class="select"></div>
                <div class="start"></div>
            </div>
            </div>
            <div class="speaker">
            <div class="grill"></div>
            <div class="grill"></div>
            <div class="grill"></div>
            <div class="grill"></div>
            </div>
        </div>
        </div>
    </div>
    </div>
</div>
<div class="container-fluid mt-5">
     <div *ngFor="let video of videos">
        <h3>

        </h3>
    </div>
    <div class="row">
        <div *ngFor="let video of videos" class="col-md-6 mb-3">
            <a [title]="video.name + ' details'" [routerLink]="['/videos', video.id]" class="video">
                <img src="assets/images/{{video.name}}.png" alt="" class="w-100">
            </a>
        </div>
    </div>
</div>
<div class="mt-3 text-center"><a class="btn btn-pink m-auto" [routerLink]="['/videos']" >All Videos</a></div>