import { Component, OnInit } from '@angular/core';
import { videos } from '../videos';

@Component({
  selector: 'app-videos-list',
  templateUrl: './videos-list.component.html',
  styleUrls: ['./videos-list.component.scss']
})

export class VideosListComponent {
  videos = videos;
}
