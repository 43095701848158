import { Component } from '@angular/core';
import { timer } from 'rxjs';

import { videos } from '../videos';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent  {
  classApplied = false;



  
  toggleClass(){
    this.classApplied = !this.classApplied

    if (this.classApplied)  {
      console.log('x is greater than y.');

      setTimeout(() => {
        console.log('hi');
  
      }, 500);
      
    } else {
    console.log('x is less than or equal to y.'); //This will be executed
    }
  } 

  startTimer() {  
    
    setTimeout(alert, 9000);
    // alert('s');
  }

  videos = videos;
}